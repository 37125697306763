<template>
  <div
    class="gwd-table-row narrow-template-list"
    :style="`background-color: ${templateTypeObj.color}`"
    v-if="templateTypeObj"
  >
    <span>{{ templateTypeObj.description }}</span>
    <span>{{ template.description }}</span>
    <span>{{ template.price }} € / {{ template.unit }}</span>
    <div class="flex justify-end">
      <button class="btn-primary" @click="selectTemplate">
        <span class="typcn typcn-plus icon"></span>
        <span class="label">lisa</span>
      </button>
    </div>
  </div>
</template>
<script>
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import { mapGetters } from "vuex";

export default {
  name: "TemplateItem",
  props: {
    template: {
      type: Object,
      default: null,
    },
  },
  mixins: [CostTypeParser],
  data() {
    return {
      templateTypeObj: null,
    };
  },
  mounted() {
    this.templateTypeObj = this.templateTypes.find(
      (x) => x.type === this.template.type
    );
  },
  methods: {
    selectTemplate() {
      this.$emit("selectTemplate", this.template);
    },
  },
  computed: {
    ...mapGetters({
      templateTypes: "companyVariables/templateTypes",
    }),
  },
};
</script>

<style scoped lang="scss"></style>
