<template>
  <div class="modal">
    <div class="modal-body modal-large" v-on-clickaway:mousedown="closeModal">
      <div class="modal-content flex-col text-sm">
        <div v-if="itemInited">
          <div class="flex flex-row mb-2">
            <button class="btn-primary mr-2" @click="toggleAll">
              Vali kõik
            </button>
            <button class="btn-primary mx-2" @click="toggleAllTasks">
              Vali kõik tööd
            </button>
          </div>
          <div class="flex flex-row flex-wrap w-full">
            <div
              v-for="task in totalCosts.filter((x) => x.costs.length > 0)"
              :key="task.id"
              class="flex w-1/2 flex-col px-2"
            >
              <div class="flex flex-row mb-2 items-center justify-between">
                <span>{{ task.title }}</span>
                <button class="btn-primary" @click="toggleTaskCosts(task)">
                  Lisa kõik
                </button>
              </div>
              <div
                v-for="cost in getTaskCosts(task)"
                :key="`${cost.type}${cost.id}`"
                class="gwd-table"
              >
                <div class="gwd-table-row narrow-total-cost-list">
                  <gwd-checkbox
                    :value="addedItems.includes(cost)"
                    :base-val="addedItems.includes(cost)"
                    @input="toggleItem(cost)"
                  />
                  <div
                    class="flex flex-row mr-3"
                    v-html="parseCostColorAndName(cost.type)"
                    style="min-width: 7px"
                  />
                  <div
                    class="flex"
                    @mouseenter="iss = cost"
                    @mouseleave="iss = null"
                    :class="iss !== cost ? 'truncate mr-3' : 'z-index-1'"
                  >
                    {{ cost.description }}
                  </div>
                  <div class="flex">
                    <span class="mr-1"> {{ cost.amount }}</span>
                    <span> {{ cost.unit }}</span>
                  </div>
                  <div class="flex items-center">
                    <span class="w-1/4"> {{ cost.price }} €</span>
                    <span class="w-1/2">
                      {{ cost.vatType ? cost.vatType.description : "-" }}
                    </span>
                    <span class="w-1/4"> {{ priceWithVat(cost) }} €</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                noTaskCosts &&
                Object.keys(noTaskCosts).length > 0 &&
                noTaskCosts.costs.length > 0
              "
              class="flex w-1/2 flex-col px-2"
            >
              <div class="flex flex-row mb-2 items-center justify-between">
                <span>Tööta</span>
                <button class="btn-primary" @click="toggleNoTaskCosts">
                  Lisa kõik
                </button>
              </div>
              <div
                v-for="cost in noTaskCosts.costs"
                :key="`${cost.type}${cost.id}`"
                class="gwd-table"
              >
                <div class="gwd-table-row narrow-total-cost-list">
                  <gwd-checkbox
                    :value="addedItems.includes(cost)"
                    :base-val="addedItems.includes(cost)"
                    @input="toggleItem(cost)"
                  />
                  <div
                    class="flex flex-row mr-3"
                    v-html="parseCostColorAndName(cost.type)"
                    style="min-width: 7px"
                  />
                  <span class="truncate hover:text-clip">{{
                    cost.description
                  }}</span>
                  <div class="flex justify-center">
                    <span class="mr-1"> {{ cost.amount }}</span>
                    <span> {{ cost.unit }}</span>
                  </div>
                  <div class="flex items-center">
                    <span class="w-1/4"> {{ cost.price }} €</span>
                    <span class="w-1/2">
                      {{ cost.vatType ? cost.vatType.description : "-" }}
                    </span>
                    <span class="w-1/4"> {{ priceWithVat(cost) }} €</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex my-4 items-center gap-x-3">
            <span>Valitud kulude arv:</span>
            <span> {{ addedItems.length }}</span>
            <span>Kulud kokku:</span>
            <span class="font-bold"> {{ totalAddedCosts }}€</span>
            <span>Hinnad kokku:</span>
            <span class="font-bold">{{ totalAddedPrices }}€</span>
          </div>
          <div
            class="grid grid-cols-9 w-full p-2 items-center hover:bg-offwhite"
          >
            <div class="pr-2">
              <select
                v-model="item.serviceObj"
                class="w-full"
                :class="
                  showInvalid && !item.serviceObj
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
                @change="
                  item.description
                    ? ''
                    : (item.service_name = item.serviceObj.service_name)
                "
              >
                <option
                  v-for="serviceObj in serviceObjects"
                  :key="serviceObj.id"
                  :value="serviceObj"
                >
                  {{
                    `${serviceObj.service_code} - ${serviceObj.service_name}`
                  }}
                </option>
              </select>
            </div>
            <div class="no-hover col-span-2 pr-2">
              <input
                type="text"
                v-model="item.description"
                class="w-full description"
              />
            </div>
            <div class="no-hover pr-2">
              <decimal-input
                v-model.number="item.amount"
                class="w-full"
                :class="
                  showInvalid && !item.amount
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              />
            </div>
            <div class="no-hover pr-2">
              <decimal-input
                step="0.01"
                v-model="item.price"
                class="w-full"
                :class="
                  showInvalid && !item.price
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              />
            </div>
            <div class="no-hover pr-2">
              <select v-model="item.unit">
                <option :value="'tk'">tk</option>
                <option :value="'h'">h</option>
                <option :value="'m'">m</option>
                <option :value="'km'">km</option>
                <option :value="'l'">l</option>
                <option :value="'m²'">m²</option>
                <option :value="'m³'">m³</option>
                <option :value="'kg'">kg</option>
                <option :value="'t'">t</option>
                <option :value="'paev'">päev</option>
                <option :value="'kuu'">kuu</option>
              </select>
            </div>
            <div class="no-hover pr-2">
              <span> {{ item.amount * item.price }}</span>
            </div>
            <div class="no-hover pr-2">
              <select
                v-if="taxTypes"
                v-model="item.taxType"
                class="max-w-full"
                :class="
                  showInvalid && !item.taxType
                    ? 'ring-danger ring-opacity-50 ring-2'
                    : ''
                "
              >
                <option
                  v-for="taxType in taxTypes.filter((x) => x.outgoing_tax_code)"
                  :key="taxType.id"
                  :value="taxType"
                >
                  {{
                    taxType
                      ? `${taxType.description} (${
                          taxType.tax_percentage ? taxType.tax_percentage : "0"
                        }%)`
                      : "-"
                  }}
                </option>
              </select>
            </div>
            <div>
              <span
                >{{ round2(item.total) }}
                {{ currency ? currency.sign : "€" }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="saveCosts">Salvesta</button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Revenue } from "@/assets/types/Revenue";
import DecimalInput from "../reusable/DecimalInput.vue";
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import { costWithCoef, priceWithVat, round2 } from "@/assets/utils/commonMath";
export default {
  name: "TotalCostTemplateRevenueGeneratorModal",
  components: {
    DecimalInput,
  },
  props: {
    template: {
      type: Object,
      default: null,
    },
    totalCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    noTaskCosts: {
      type: Object,
      default: null,
    },
  },
  mixins: [CostTypeParser],
  data() {
    return {
      item: null,
      itemInited: false,
      showInvalid: false,
      currency: null,
      iss: null,
      addedItems: [],
      colors: {
        workerCost: "#D8CD02",
        resourceCost: "#3D348B",
        equipmentCost: "#6F8F72",
        extraCost: "#A41623",
        supplierCost: "#122112",
      },
      names: {
        workerCost: "Palk",
        resourceCost: "Ressurss",
        equipmentCost: "Tehnika",
        extraCost: "Lisatöö",
        supplierCost: "Hankija",
      },
    };
  },
  methods: {
    priceWithVat,
    costWithCoef,
    round2,
    closeModal() {
      this.$emit("closeModal");
    },
    saveCosts() {
      this.item.revenueTypeArray = this.addedItems.map(
        (x) => `${x.type}${x.id}`
      );
      this.$emit("addItems", this.item);
      this.closeModal();
    },
    toggleItem(cost) {
      if (!this.addedItems.includes(cost)) {
        this.addedItems.push(cost);
      } else {
        this.addedItems.splice(
          this.addedItems.indexOf(this.addedItems.find((x) => x === cost)),
          1
        );
      }
    },
    toggleAll() {
      this.toggleNoTaskCosts();
      this.toggleAllTasks();
    },
    toggleAllTasks() {
      this.totalCosts.forEach((task) => this.toggleTaskCosts(task));
    },
    toggleNoTaskCosts() {
      [...this.noTaskCosts.costs].forEach((cost) => {
        if (!this.addedItems.includes(cost)) {
          this.addedItems.push(cost);
        } else {
          this.addedItems.splice(
            this.addedItems.indexOf(this.addedItems.find((x) => x === cost)),
            1
          );
        }
      });
    },
    toggleTaskCosts(task) {
      task.costs.forEach((cost) => {
        if (!this.addedItems.includes(cost)) {
          this.addedItems.push(cost);
        } else {
          this.addedItems.splice(
            this.addedItems.indexOf(this.addedItems.find((x) => x === cost)),
            1
          );
        }
      });
    },
    getTaskCosts(task) {
      return [...(task.costs ?? [])];
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
    let vat = this.taxTypes.find((x) => x.id === this.template.vat);
    let serviceCode = this.serviceObjects.find(
      (x) => x.id === this.template.service_code
    );
    if (!serviceCode && this.defaultServiceCode) {
      this.serviceObjects.find((x) => x.id === this.defaultServiceCode);
    }
    this.item = new Revenue(
      null,
      null,
      1,
      this.template.unit,
      vat,
      this.template.price,
      this.addedItems.map((x) => `${x.type}${x.id}`),
      this.template.description
    );
    this.itemInited = true;
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      taxTypes: "companyVariables/taxTypes",
      serviceObjects: "companyVariables/serviceObjects",
      defaultServiceCode: "companyData/defaultServiceCode",
    }),
    totalAddedCosts() {
      if (this.addedItems.length === 0) {
        return 0;
      }
      return round2(
        this.addedItems
          .map((x) => {
            if (x.cost_coefficient) {
              return x.amount * x.cost * x.cost_coefficient;
            }
            return x.amount * x.cost;
          })
          .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr))
      );
    },
    totalAddedPrices() {
      if (this.addedItems.length === 0) {
        return 0;
      }
      return round2(
        this.addedItems
          .map((x) => {
            if (x.vatType) {
              return (
                x.amount * x.price +
                (x.amount * x.price * x.vatType.tax_percentage) / 100
              );
            }
            return x.amount * x.price;
          })
          .reduce((prev, curr) => parseFloat(prev) + parseFloat(curr))
      );
    },
  },
};
</script>
<style scoped lang="scss">
.narrow-total-cost-list {
  //                   selected type name amount total
  grid-template-columns: 0.1fr 0.2fr 0.6fr 0.2fr 0.6fr;
}
</style>
