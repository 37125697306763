<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-content flex-col">
        <div class="gwd-table">
          <div class="gwd-table-header grid-cols-5">
            <span>Järjekorra nr</span>
            <span class="col-span-2">Maksja</span>
            <span class="col-span-2">Arve</span>
          </div>
          <div
            v-for="revenueList in revenueLists"
            :key="revenueList.id"
            class="gwd-table-row grid-cols-5 cursor-pointer"
            @click="selectedRevenueList = revenueList"
            :class="selectedRevenueList === revenueList ? 'bg-offwhite' : ''"
          >
            <span>{{ revenueList.id + 1 }}</span>
            <span class="col-span-2">{{
              revenueList.payer
                ? revenueList.payer.name
                : revenueList.client.name
            }}</span>
            <span class="col-span-2">
              {{ revenueList.invoice ? revenueList.invoice.id : "-" }}</span
            >
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar flex">
        <button
          class="mr-3"
          @click="selectRevenueList"
          :class="selectedRevenueList ? 'btn-primary' : 'btn-disabled'"
          :disabled="selectedRevenueList === null"
        >
          <span class="label">Kinnita</span>
        </button>
        <button class="btn-danger mr-3" @click="closeModal">
          <span class="label">Tühista</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RevenueListSelector",
  props: {
    revenueLists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedRevenueList: null,
    };
  },
  methods: {
    selectRevenueList() {
      this.$emit("revenueSelectorSelected", this.selectedRevenueList.id);
    },
    closeModal() {
      this.$emit("reset");
    },
  },
};
</script>

<style scoped lang="scss"></style>
