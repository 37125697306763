<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-content flex-col">
        <div class="gwd-table">
          <div class="gwd-table-header grid-cols-9">
            <span class="col-span-2">Kirjeldus</span>
            <span>Kogus</span>
            <span>Kulu</span>
            <span>Koefitsent</span>
            <span>Hind</span>
            <span>Käibemaks</span>
            <span>Kulu/Hinna summa</span>
            <span />
          </div>
          <div
            class="gwd-table-row grid-cols-9"
            v-for="cost in markAsPaid"
            :key="`${cost.type}${cost.id}`"
          >
            <span class="col-span-2"> {{ cost.name }}</span>
            <span>{{ cost.amount }} {{ cost.unit }}</span>
            <span>{{ cost.cost }}</span>
            <span>{{ cost.cost_coefficient }}</span>
            <span>{{ cost.price }}</span>
            <span>{{ cost.vatType ? cost.vatType.description : "-" }}</span>
            <div class="flex items-center">
              <span class="w-1/2"> {{ costWithCoef(cost) }} €</span>
              <span class="w-1/2"> {{ priceWithVat(cost) }} €</span>
            </div>
            <button class="btn-danger ml-auto" @click="removeCost(cost)">
              <span class="label">X</span>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="markCostsAsPaid">
          Salvesta
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>
<script>
import { costWithCoef, priceWithVat } from "../../assets/utils/commonMath";

export default {
  name: "MarkCostsAsPaidModal",
  props: {
    markAsPaid: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    priceWithVat,
    costWithCoef,
    closeModal() {
      this.$emit("closeModal");
    },
    removeCost(e) {
      this.$emit("removeCost", e);
    },
    markCostsAsPaid() {
      this.$emit("markCostsAsPaid");
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss"></style>
