<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="p-3" :class="!addProject ? 'modal-header' : ''">
        <gwd-formlabel title="Lisa ressurssi kulu" :heading-level="3" />
      </div>
      <div class="modal-content flex-col">
        <div v-if="!resource" class="flex flex-row w-full">
          <div class="flex flex-col w-3/5 mr-2">
            <div
              class="p-1 flex-row items-center w-full gap-x-2 bg-offwhite grid assigned-resource-table"
            >
              <span></span>
              <span>Ressurss</span>
              <span>Laoseis</span>
              <span>Käibemaks</span>
              <span>Kulu</span>
              <span>Hind</span>
              <span>Vali kogus</span>
            </div>
            <div
              v-for="(newAssignment, index) in assignedResources"
              :key="index"
              class="flex p-1 mb-1 items-center"
              :class="
                newAssignment.assigned_amount >
                  newAssignment.remaining_amount ||
                newAssignment.assigned_amount <= 0
                  ? 'ring-2 rounded ring-danger'
                  : ''
              "
            >
              <div
                class="grid flex-row items-center w-full gap-x-2 assigned-resource-table"
              >
                <button
                  class="btn-danger"
                  @click="toggleAssignedResource(newAssignment)"
                >
                  <span class="typcn-times typcn icon" />
                </button>
                <span> {{ newAssignment.name }}</span>
                <span>
                  {{ newAssignment.remaining_amount }}
                  {{ newAssignment.unit }}
                </span>
                <select v-model="newAssignment.vatType" class="max-w-full">
                  <option
                    v-for="taxType in taxTypes.filter(
                      (x) => x.outgoing_tax_code
                    )"
                    :key="taxType.id"
                    :value="taxType"
                  >
                    {{ `${taxType.description}` }}
                  </option>
                </select>
                <span>{{ newAssignment.cost }}€</span>
                <span>{{ newAssignment.price }} €</span>
                <decimal-input v-model.number="newAssignment.assigned_amount" />
              </div>
            </div>
          </div>
          <div class="flex flex-col w-2/5 ml-2">
            <existing-resource-selector
              @resourceSelected="toggleAssignedResource"
              :toggled-resources="assignedResources.map((x) => x.id)"
            />
          </div>
        </div>
        <div v-if="resource" class="flex flex-row w-full">
          <div class="flex flex-col w-1/2 mr-2">
            <div class="flex flex-col justify-center mb-1">
              <gwd-formlabel title="Nimetus"></gwd-formlabel>
              <input
                type="text"
                class="add-offer-input"
                v-model="costData.name"
              />
            </div>
            <div class="flex flex-col justify-center mb-1">
              <gwd-formlabel title="Kulu"></gwd-formlabel>
              <decimal-input
                class="add-offer-input"
                step="0.1"
                min="0"
                v-model.number="costData.cost"
              />
            </div>
            <div class="flex flex-col justify-center mb-1">
              <gwd-formlabel title="Hind"></gwd-formlabel>
              <decimal-input
                class="add-offer-input"
                step="0.1"
                min="0"
                v-model.number="costData.price"
              />
            </div>
            <div class="flex flex-col justify-center mb-1">
              <gwd-formlabel title="Kogus"></gwd-formlabel>
              <decimal-input
                class="add-offer-input"
                step="0.1"
                min="0"
                v-model.number="costData.amount"
              />
            </div>
            <div class="flex justify-center pl-1 flex-col mobile:px-0">
              <gwd-formlabel title="Ühik"></gwd-formlabel>
              <select class="add-offer-input" v-model="costData.unit">
                <option v-for="unit in units" :value="unit" :key="unit">
                  {{ unit }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="resource" class="flex flex-col w-1/2 ml-2">
            <div class="flex mb-2 items-center">
              <gwd-formlabel title="Tellimus" class="mr-2"></gwd-formlabel>
              <div v-if="!project">
                <button class="btn-primary" @click="addProject = true">
                  <span class="label">Vali tellimus</span>
                </button>
              </div>
            </div>
            <div v-if="project" class="flex flex-row justify-between mb-2">
              <span>Valitud tellimus: {{ project.title }}</span>
              <button class="btn-primary" @click="project = null">
                <span class="label">Muuda</span>
              </button>
            </div>

            <div class="flex flex-col my-2" v-if="project">
              <gwd-formlabel title="Tellimuse tööd" />
              <div
                class="flex flex-row items-center justify-between"
                v-if="selectedTask"
              >
                <span class="label">Valitud töö: {{ selectedTask.title }}</span>
                <button class="btn-primary" @click="selectedTask = null">
                  <span class="label">Muuda</span>
                </button>
              </div>
              <div
                class="flex flex-wrap flex-col overflow-y-hidden overflow-x-scroll"
                v-else
              >
                <div
                  v-for="task in projectTasks"
                  :key="task.id"
                  class="p-3 cursor-pointer w-full border flex flex-row justify-between items-center rounded-gwdhalf mobile:w-full duration-100 mb-1"
                  :class="
                    selectedTask && task.id === selectedTask.id
                      ? 'shadow  border-primary'
                      : 'border-transparent'
                  "
                  @click="handleTaskSelection(task)"
                >
                  <h4 class="mr-2 truncate">{{ task.title }}</h4>
                  <span class="ml-2" v-html="parseTaskStatus(task.status)">
                  </span>
                </div>
              </div>
            </div>
            <span
              v-if="
                resourceAssignment &&
                resourceAssignment.task &&
                Object.keys(resourceAssignment.task).length > 0
              "
            >
              {{ resourceAssignment.task.title }}
            </span>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="saveResourceCost">
          Salvesta
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
    <project-selector
      v-if="addProject"
      @closeModal="addProject = false"
      @projectAdded="selectProject"
      :convert="false"
      class="z-999"
    />
  </div>
</template>

<script>
import ProjectSelector from "@/components/tasks/ProjectSelector";
import { mapGetters } from "vuex";
import { StatusParser } from "@/assets/mixins/GewodoMixins";
import DecimalInput from "../../reusable/DecimalInput.vue";
import ExistingResourceSelector from "@/components/company/resources/ExistingResourceSelector.vue";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
export default {
  name: "AddResourceCostModal",
  components: { ExistingResourceSelector, ProjectSelector, DecimalInput },
  props: {
    resourceAssignment: {
      type: Object,
      default: () => {
        return null;
      },
    },
    resource: {
      type: Object,
      default: () => {
        return null;
      },
    },
    presetTask: {
      type: Number,
      default: null,
    },
    presetProject: {
      type: String,
      default: null,
    },
  },
  mixins: [StatusParser],

  data() {
    return {
      subPage: 1,
      addProject: false,
      project: null,
      selectedTask: null,
      projectTasks: [],
      assignedResources: [],
      costData: {
        project: null,
        task: null,
        resource_assignment: null,
        invoice: null,
        name: "",
        amount: 0,
        unit: "tk",
        cost: 0,
        price: 0,
      },
    };
  },
  methods: {
    toggleAssignedResource(e) {
      if (this.assignedResources.includes(e)) {
        this.assignedResources.splice(
          this.assignedResources.indexOf(
            this.assignedResources.find((x) => x === e)
          ),
          1
        );
        e.assigned_amount = null;
      } else {
        e.vatType = e.vat ? this.taxTypes.find((x) => x.id === e.vat) : null;
        this.assignedResources.push(e);
      }
    },
    loadProjectTasks(project) {
      this.apiRequest(
        `calendar/${this.companyId}/${project.uuid}/tasks/?period=all`,
        "get",
        true
      ).then((res) => {
        this.projectTasks = res.data;
      });
    },
    handleTaskSelection(e) {
      this.selectedTask = e;
    },
    selectProject(e) {
      this.project = e;
      this.loadProjectTasks(e);
    },
    saveResourceCost() {
      if (!this.resource) {
        let promises = [];
        for (let resource of this.assignedResources) {
          promises.push(
            this.apiRequest(
              `costs/${this.companyId}/resource/${resource.id}/costs/create/`,
              "post",
              true,
              {
                task: this.presetTask,
                project: this.presetProject,
                resource_assignment: null,
                invoice: null,
                name: resource.name,
                amount: resource.assigned_amount,
                unit: resource.unit,
                cost: resource.cost,
                price: resource.price,
                vat: resource.vatType ? resource.vatType.id : null,
              }
            )
          );
        }
        Promise.all(promises).then(() => {
          this.closeModal();
          EventBus.$emit("costsUpdated");
        });
      }
      if (this.project) {
        this.costData.project = this.project.uuid;
      }
      if (this.selectedTask) {
        this.costData.task = this.selectedTask.id;
      }
      this.apiRequest(
        `costs/${this.companyId}/resource/${this.resource.id}/costs/create/`,
        "post",
        true,
        this.costData
      ).then((res) => {
        if (res.status === 201) this.closeModal();
      });
    },
    closeModal() {
      this.$emit("closeModal");
      this.$store.commit("modals/taskModal/closeAddResourceCostModal");
    },
    initData() {
      if (this.resourceAssignment) {
        this.costData.resource_assignment = this.resourceAssignment.id;
        this.costData.amount =
          this.resourceAssignment.assigned_amount -
          this.resourceAssignment.returned_amount;
        this.project = this.resourceAssignment.project;
      }
      if (this.presetTask) {
        this.handleTaskSelection(this.presetTask);
      }
      if (this.resource) {
        this.costData.price = this.resource.price;
        this.costData.cost = this.resource.cost;
        this.costData.unit = this.resource.unit;
        this.costData.name = this.resource.name;
      }
    },
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      units: "companyVariables/units",
      taxTypes: "companyVariables/taxTypes",
    }),
  },
  async mounted() {
    if (!this.taxTypes) await this.$store.dispatch("taxTypes/getTaxTypes");
    this.initData();
  },
};
</script>
<style lang="scss" scoped>
.assigned-resource-table {
  grid-template-columns: 0.05fr 0.25fr 0.2fr 0.25fr 0.1fr 0.1fr 0.2fr;
}
</style>
