<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-content flex-col no-padding">
        <div
          class="flex justify-between items-start mobile:flex-col h-10 border-b border-offwhite-dark"
        >
          <div class="flex w-3/5 px-2 bg-offwhite h-full items-center">
            <h3>Tööle määratud</h3>
          </div>
          <div
            class="flex w-2/5 mobile:w-full ml-auto mobile:ml-0 h-full"
            v-if="isAdmin || isManager"
          >
            <div
              class="client-selector-tab w-1/2 border-l"
              @click="selectExisting = true"
              :class="selectExisting ? 'client-selector-tab-active' : ''"
            >
              <span class="typcn typcn-th-list mr-2"></span>
              <span>Vali olemasolev</span>
            </div>
            <div
              class="client-selector-tab w-1/2 rounded-tr"
              @click="selectExisting = false"
              :class="!selectExisting ? 'client-selector-tab-active' : ''"
            >
              <span class="typcn typcn-plus mr-2"></span>
              <span>Lisa uus</span>
            </div>
          </div>
          <div
            class="flex w-1/2 mobile:w-full items-center h-full px-2 bg-offwhite rounded-tr"
            v-else
          >
            <h3>Eksisteerivad ressursid</h3>
          </div>
        </div>
        <div class="flex mobile:flex-col w-full flex-1">
          <div
            class="flex flex-col w-3/5 mobile:w-full mobile:mr-0 border-r border-offwhite-dark"
          >
            <div
              v-if="isAdmin || isManager"
              class="assigned-resources-col-manager bg-offwhite px-2 py-1 gap-x-2"
            >
              <h4>Ressurss</h4>
              <h4>Laokogus</h4>
              <h4>Kulu</h4>
              <h4>Käibemaks</h4>
              <h4>Määratud</h4>
              <h4 />
            </div>
            <div
              v-else
              class="assigned-resources-col bg-offwhite px-2 py-1 gap-x-2"
            >
              <h4>Ressurss</h4>
              <h4>Laokogus</h4>
              <h4>Määratud</h4>
              <h4 />
            </div>
            <div
              v-for="(newAssignment, index) in assignedResources"
              :key="index"
              class="px-2 py-1 gap-x-2"
              :class="[
                newAssignment.assigned_amount >
                  newAssignment.remaining_amount ||
                newAssignment.assigned_amount <= 0
                  ? 'bg-danger bg-opacity-5'
                  : '',
                isManager || isAdmin
                  ? 'assigned-resources-col-manager'
                  : 'assigned-resource-col',
              ]"
            >
              <span> {{ newAssignment.name }}</span>
              <span>
                {{ newAssignment.remaining_amount }}
              </span>
              <span v-if="isManager || isAdmin">{{ newAssignment.cost }}</span>
              <span v-if="isManager || isAdmin">{{
                newAssignment.vat
                  ? taxTypes.find((x) => x.id === newAssignment.vat).description
                  : "-"
              }}</span>
              <div>
                <decimal-input v-model.number="newAssignment.assigned_amount" />
              </div>
              <div class="flex justify-end">
                <button
                  class="btn-danger leading-none"
                  @click="toggleAssignedResource(newAssignment)"
                >
                  <span class="typcn typcn-times text-xl"></span>
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col w-2/5 mobile:w-full mobile:ml-0 gap-y-2 p-2"
          >
            <existing-resource-selector
              v-if="selectExisting"
              @resourceSelected="toggleAssignedResource"
              :toggled-resources="assignedResources.map((x) => x.id)"
            />
            <add-resource-form v-if="!selectExisting" ref="addResourceForm" />
            <div class="flex gap-x-2 items-center" v-if="!selectExisting">
              <button class="btn-primary" @click="saveResource">
                <span class="label">Lisa</span>
              </button>
              <button class="btn-danger" @click="resetAdding">
                <span class="label">Tühista</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-bottom-bar">
        <button class="btn-primary mr-3" @click="addResourceAssignment">
          Salvesta
        </button>
        <button class="btn-danger" @click="closeModal">Sulge</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ExistingResourceSelector from "@/components/company/resources/ExistingResourceSelector.vue";
import AddResourceForm from "@/components/forms/AddResourceForm.vue";
import DecimalInput from "../../reusable/DecimalInput.vue";
import { EventBus } from "../../../assets/mixins/GewodoMixins";
export default {
  name: "AddResourceAssignmentModal",
  components: { AddResourceForm, ExistingResourceSelector, DecimalInput },
  props: {
    task: {
      type: Number,
      default: null,
    },
    project: {
      type: String,
      default: null,
    },
    selectorsEnabled: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      resources: [],
      resourcesLoaded: false,
      selectedTask: null,
      selectedProject: null,
      assignedResources: [],
      selectExisting: true,
    };
  },
  methods: {
    toggleAssignedResource(e) {
      if (this.assignedResources.includes(e)) {
        this.assignedResources.splice(
          this.assignedResources.indexOf(
            this.assignedResources.find((x) => x === e)
          ),
          1
        );
        e.assigned_amount = null;
      } else {
        this.assignedResources.push(e);
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    saveResource() {
      const resource = this.$refs.addResourceForm.getResource();

      this.apiRequest(
        `template/${this.companyId}/resources/add/`,
        "post",
        true,
        resource
      ).then((res) => {
        if (res.status === 201) {
          this.loadCompanyResources();
          this.selectExisting = true;
        }
      });
    },
    resetAdding() {
      this.$refs.addResourceForm.reset();
      this.selectExisting = true;
    },
    loadCompanyResources() {
      this.apiRequest(
        `template/${this.companyId}/resources/`,
        "get",
        true
      ).then((res) => {
        this.resources = res.data;
        this.resourcesLoaded = true;
      });
    },
    addResourceAssignment() {
      let promises = [];
      this.assignedResources.forEach((e) => {
        let data = {
          assigned_amount: e.assigned_amount,
        };
        if (this.task) {
          data.task = this.task;
        }
        if (this.project) {
          data.project = this.project;
        }
        promises.push(
          this.apiRequest(
            `template/${this.companyId}/resources/${e.id}/assign/`,
            "post",
            true,
            data
          )
        );
      });
      Promise.all(promises).then(() => {
        EventBus.$emit("resourcesAssigned");
        this.closeModal();
      });
    },
  },
  async mounted() {
    if ((this.isAdmin || this.isManager) && !this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    this.loadCompanyResources();
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      taxTypes: "companyVariables/taxTypes",
    }),
  },
};
</script>
<style lang="scss">
.assigned-resources-col {
  @apply grid;
  grid-template-columns: 0.6fr 0.1fr 0.1fr 0.2fr;
}
.assigned-resources-col-manager {
  @apply grid;
  //grid-template-columns: 0.4fr 0.2fr 0.1fr 0.15fr 0.1fr 0.1fr;
  //                     name amount cost vat assigned_am cancel
  grid-template-columns: 0.3fr 0.2fr 0.1fr 0.3fr 0.2fr 0.1fr;
}
</style>
