<template>
  <div class="modal relative">
    <div
      class="modal-body modal-full mobile:p-0 relative z-10 max-h-full"
      v-on-clickaway:mousedown="handleClickaway"
      v-if="taskData"
    >
      <div class="modal-header flex-col z-50">
        <div class="flex w-full items-center border-b border-offwhite-dark">
          <div class="flex w-10/12 p-2 mobile:w-full">
            <input
              class="title-input"
              v-model="taskData.title"
              v-if="canEditStatus"
              maxlength="128"
            />
            <h3 v-else>
              {{ taskData.title }}
            </h3>
          </div>
          <div
            class="flex w-2/12 relative z-50 border-l border-offwhite-dark h-full mobile:hidden"
          >
            <div
              class="justify-start flex w-full h-full pl-2"
              :class="[
                canEditStatus ? 'cursor-pointer hover:bg-offwhite' : '',
                statusMenuOpen ? 'shadow rounded-none rounded-t' : 'rounded',
              ]"
              @click="canEditStatus ? (statusMenuOpen = !statusMenuOpen) : ''"
            >
              <span v-html="parseTaskStatus(taskData.status)"></span>
              <span
                class="typcn typcn-arrow-sorted-down ml-auto transform duration-100 flex self-center mr-2 leading-none"
                v-if="canEditStatus"
                :class="statusMenuOpen ? 'rotate-180' : ''"
              ></span>
            </div>
            <task-status-selector
              v-if="statusMenuOpen"
              :task-data="taskData"
              @changeStatus="changeTaskStatus"
              @endTask="confirmEndTaskModalActive = true"
            />
          </div>
        </div>
        <div class="tab-tray w-full">
          <div
            class="tab-tray-item"
            :class="activeView === 1 ? 'tab-tray-item-active' : ''"
            @click="activeView = 1"
          >
            <span class="tab-tray-label">Üldinfo</span>
          </div>
          <div
            class="tab-tray-item"
            :class="activeView === 2 ? 'tab-tray-item-active' : ''"
            @click="activeView = 2"
            v-if="isAdmin"
          >
            <span class="tab-tray-label">Tööajad</span>
          </div>
          <div
            class="tab-tray-item"
            :class="activeView === 3 ? 'tab-tray-item-active' : ''"
            @click="activeView = 3"
          >
            <span class="tab-tray-label">Failid</span>
          </div>
          <div
            class="tab-tray-item"
            :class="activeView === 4 ? 'tab-tray-item-active' : ''"
            @click="activeView = 4"
          >
            <span class="tab-tray-label">Kulud</span>
          </div>

          <div
            v-if="config.features.chat"
            class="tab-tray-item"
            :class="activeView === 6 ? 'tab-tray-item-active' : ''"
            @click="activeView = 6"
          >
            <span class="tab-tray-label">Vestlus</span>
          </div>
        </div>
      </div>
      <transition name="fade" :duration="200">
        <div class="flex flex-col mobile:p-0 modal-content">
          <task-info v-if="activeView === 1" ref="taskInfo" />
          <task-time-tracking v-if="activeView === 2" />
          <task-files v-if="activeView === 3" />
          <task-assignments
            v-if="activeView === 4"
            :task="taskData"
            :see-items="isAdmin || isManager"
          />
          <task-chat v-if="config.features.chat && activeView === 6" />
        </div>
      </transition>

      <div
        class="modal-bottom-bar mobile:flex-col"
        :class="taskLoaded ? '' : 'mt-auto'"
      >
        <div class="flex items-center gap-x-3 mobile:justify-between">
          <button
            v-if="canEdit"
            :disabled="!taskDataChanged || saving || !isManager"
            :class="
              !taskDataChanged || saving || !isManager
                ? 'btn-disabled'
                : 'btn-primary'
            "
            @click="editTask"
          >
            <span v-if="!saving">Salvesta</span>
            <clip-loader size="16px" v-else />
          </button>
          <button
            v-if="canEdit && taskDataChanged"
            class="btn-danger"
            @click="resetTask"
          >
            <span class="label">Tühista</span>
          </button>
          <button class="btn-danger" @click="closeModal">
            <span class="typcn typcn-times icon" />
            <span class="label">Sulge</span>
          </button>
          <button class="btn-primary" @click="createCopyLink">
            <span class="typcn typcn-clipboard icon" />
            <span class="label">Kopeeri link</span>
          </button>
        </div>
        <div
          class="ml-auto mobile:ml-0 mobile:text-sm"
          v-if="taskData && (isAdmin || isManager)"
        >
          {{
            `ID: ${taskData.id} / Autor: ${
              taskData.author ? taskData.author : "-"
            } / Loodud: ${
              taskData.created_at
                ? moment(taskData.created_at).format("DD.MM.YYYY HH:mm")
                : "-"
            }`
          }}
        </div>
        <button
          v-if="isAdmin && !taskDataChanged"
          class="btn-danger ml-2"
          @click="confirmDeleteActive = true"
        >
          <span class="typcn typcn-trash icon" />
          <span class="label">Kustuta töö</span>
        </button>
      </div>

      <confirm-modal
        v-if="confirmDeleteActive"
        text="Olete kindel, et soovite valitud töö kustutada?"
        @confirm="deleteTask()"
        @closeModal="confirmDeleteActive = false"
      />
      <confirm-modal
        v-if="confirmEndTaskModalActive"
        text="Olete kindel, et soovite valitud töö kinnitada? Peale seda ei saa enam tööaegu käivitada."
        @confirm="changeTaskStatus(6)"
        @closeModal="confirmEndTaskModalActive = false"
      />
      <add-resource-assignment-modal
        v-if="showAddResourceAssignmentModal"
        :task="taskData.id"
        @closeModal="
          $store.commit('modals/taskModal/closeAddResourceAssignmentModal')
        "
        :style="{ 'z-index': 99 }"
      />
      <add-extra-cost-modal
        v-if="extraCostModal.show"
        :task="taskData.id"
        :task-workers="taskData.workers"
        :planning-cost="extraCostModal.isPlanning"
        @closeModal="$store.commit('modals/taskModal/closeAddExtraCostModal')"
      />
      <add-supplier-cost-modal
        v-if="supplierCostModal.show"
        :supplier="null"
        :task="taskData.id"
        :planning-cost="supplierCostModal.isPlanning"
        @closeModal="
          $store.commit('modals/taskModal/closeAddSupplierCostModal')
        "
      />
      <add-resource-cost-modal
        v-if="resourceCostModal"
        :preset-task="taskData.id"
      />
      <new-project-modal
        v-if="showNewProjectModal"
        :task-data="modalData"
        @closeModal="
          $store.commit('modals/taskModal/setShowNewProjectModal', false)
        "
        @projectAdded="
          (e) => $store.dispatch('modals/taskModal/convertToProject', e)
        "
        class="z-999"
      />
      <timer-map-modal
        v-if="timerMapModal.show"
        :task-data="taskData"
        @closeModal="$store.commit('modals/taskModal/closeTimerMap')"
      />
      <location-inaddress-modal
        v-if="locationModal.show"
        :data="locationModal.data"
        @closeModal="$store.commit(`modals/taskModal/closeLocationModal`)"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { TextareaAutogrowDirective } from "vue-textarea-autogrow-directive";
import AddResourceAssignmentModal from "@/components/tasks/modals/AddResourceAssignmentModal.vue";
import ConfirmModal from "@/components/reusable/ConfirmModal";
import TaskStatusSelector from "@/components/reusable/TaskStatusSelector";
import { ee } from "vuejs-datepicker/dist/locale";
import { EventBus, StatusParser } from "@/assets/mixins/GewodoMixins";
import { mapGetters, mapState } from "vuex";
import TaskInfo from "./taskmodal/TaskInfo";
import TaskFiles from "./taskmodal/TaskFiles";
import TaskAssignments from "@/components/tasks/TaskAssignments";
import TaskChat from "@/components/tasks/taskmodal/TaskChat";
import TaskTimeTracking from "./taskmodal/TaskTimeTracking.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import AddExtraCostModal from "@/components/tasks/modals/AddExtraCostModal.vue";
import AddSupplierCostModal from "@/components/company/suppliers/AddSupplierCostModal.vue";
import AddResourceCostModal from "@/components/company/resources/AddResourceCostModal.vue";
import config from "../../../gewodo.config.json";
import NewProjectModal from "@/components/project/NewProjectModal.vue";
import TimerMapModal from "@/components/tasks/taskmodal/TimerMapModal.vue";
import LocationInaddressModal from "@/components/reusable/LocationInaddressModal.vue";
export default {
  props: {
    userRole: { type: String, default: "R6" },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      activeView: 1,
      draft: null,
      draftLoaded: false,
      addingWorkers: false,
      taskLoaded: false,
      moment: moment,
      ee: ee,
      taskStoppers: [],
      conflictingWorkers: [],
      selectableWorkers: [],
      stopper: null,
      stopperTimers: [],
      stoppersInited: false,
      statusMenuOpen: false,
      timeHovered: false,
      confirmDeleteActive: false,
      confirmEndTaskModalActive: false,
      config: config,
    };
  },
  components: {
    LocationInaddressModal,
    TimerMapModal,
    NewProjectModal,
    TaskAssignments,
    ConfirmModal,
    TaskStatusSelector,
    TaskInfo,
    TaskFiles,
    TaskChat,
    TaskTimeTracking,
    ClipLoader,
    AddResourceAssignmentModal,
    AddExtraCostModal,
    AddSupplierCostModal,
    AddResourceCostModal,
  },
  mixins: [StatusParser],
  mounted() {
    this.getData();
    document.addEventListener("keydown", this.handleKeyDown);

    this.$store.dispatch("timetracking/retrieveTimers");
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    for (let timer of this.stopperTimers) clearInterval(timer);
    document.body.classList.remove("modal-open");
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    getData() {
      this.retrieveTask(true);
      this.activeView = this.modalData.activeView ?? 1;
    },
    retrieveTask(full) {
      if (this.$store.state.companyData.isClient) {
        this.apiRequest(
          `tasks/${this.$route.params.projectId}/${this.data.task}/`,
          "get",
          true
        );
      } else {
        if (full) {
          this.taskLoaded = false;
        }
        this.$store.dispatch(
          "modals/taskModal/retrieveTask",
          this.modalData.task
        );
      }
    },
    closeModal() {
      this.$store.dispatch("modals/taskModal/close");
    },
    editTask() {
      this.$store.dispatch("modals/taskModal/saveTask");
    },
    resetTask() {
      this.$store.dispatch("modals/taskModal/retrieveTask", this.taskData.id);
    },
    deleteTask() {
      this.apiRequest(`tasks/${this.taskData.id}/delete/`, "delete", true).then(
        (res) => {
          if (res.status === 200) {
            EventBus.$emit("updateTasks");
            this.closeModal();
          }
        }
      );
    },
    createCopyLink() {
      navigator.clipboard.writeText(
        `https://gewodo.ava.ee/calendar/?task_id=${this.taskData.id}`
      );
    },
    changeTaskStatus(status) {
      const data = { status: status.toString() };

      this.apiRequest(`tasks/${this.data.task}/edit/`, "put", true, data).then(
        (res) => {
          if (res.status === 200) {
            EventBus.$emit("updateTasks");
            this.retrieveTask(false);
            this.statusMenuOpen = false;
            this.confirmEndTaskModalActive = false;
          }
        }
      );
    },
    handleKeyDown(e) {
      if (!this.taskDataChanged && e.keyCode === 27) {
        this.closeModal();
      }
    },
    handleClickaway(e) {
      if (
        e.target.id !== "message-close-button" &&
        !this.$store.state.modals.fileModal.displayFileModal
      )
        this.closeModal();
    },
  },
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  computed: {
    ...mapGetters({
      taskDataChanged: "modals/taskModal/taskDataChanged",
      modalData: "modals/taskModal/data",
      isAdmin: "companyData/isAdmin",
      isManager: "companyData/isManager",
      saving: "modals/taskModal/saving",
      showAddResourceAssignmentModal:
        "modals/taskModal/showAddResourceAssignmentModal",
      extraCostModal: "modals/taskModal/extraCostModal",
      supplierCostModal: "modals/taskModal/supplierCostModal",
      resourceCostModal: "modals/taskModal/resourceCostModal",
      showNewProjectModal: "modals/taskModal/showNewProjectModal",
      timerMapModal: "modals/taskModal/timerMapModal",
      locationModal: "modals/taskModal/locationModal",
    }),
    ...mapState({
      taskData: (state) => state.modals.taskModal.taskData,
    }),
    date() {
      if (!this.data.task.all_day) {
        return (
          moment(this.taskData.start_time).format("DD.MM.YYYY HH:mm-") +
          moment(this.taskData.end_time).format("HH:mm")
        );
      } else {
        return moment(this.taskData.start_time).format("DD.MM.YYYY");
      }
    },
    canEdit() {
      if (!this.taskData) return false;
      return (
        ["R0", "R1", "R2", "R3"].includes(this.userRole) &&
        [1, 2, 3, 4, 5].includes(this.taskData.status)
      );
    },
    canEditStatus() {
      if (!this.taskData) return false;
      return (
        (["R0", "R1", "R2", "R3"].includes(this.userRole) &&
          [1, 2, 3, 4, 5].includes(this.taskData.status)) ||
        (["R4", "R5"].includes(this.userRole) &&
          [2, 3, 4].includes(this.taskData.status))
      );
    },
  },
};
</script>

<style lang="scss">
.taskmodal-datepicker {
  @apply w-full;
  div {
    input {
      @apply shadow w-full;
    }
  }
  .vdp-datepicker__calendar {
    @apply absolute z-50;
  }
}
.taskmodal-close-button {
  @apply bg-danger text-white shadow-none p-2 leading-none;
  &:hover {
    @apply bg-white border-danger text-danger shadow-dangeroutline;
    &:after {
      padding-left: 0.5rem;
      content: "Sulge";
    }
  }
}
.loading-taskmodal {
  @apply border-primary;
  div {
    @apply border-primary;
  }
}
.title-input {
  @apply p-0 shadow-none font-medium text-lg border-solid h-auto leading-none rounded-none duration-100 py-1 border-primary;
  &:focus {
    @apply ring-0 border-b;
  }
  &:hover {
    @apply border-b ring-0 border-primary-dark;
  }
}
</style>
