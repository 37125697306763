<template>
  <div class="gwd-table">
    <div class="gwd-table-header narrow-cost-list">
      <span>Tüüp</span>
      <span>Kirjeldus</span>
      <span>Kogus</span>
      <span>Kulu/Hinna summa</span>
      <span class="flex justify-end">
        <button class="btn-primary" @click="toggleShowAdded">
          {{ showAdded ? "Peida" : "Näita" }}
        </button>
      </span>
    </div>
    <template-cost
      v-for="templateType in [
        {
          id: 'resourceCosts',
          costs: noTaskCosts.costs.filter((x) => x.type === 'resourceCost'),
          type: 3,
          name: 'Ressurss',
        },
        {
          id: 'supplierCosts',
          costs: noTaskCosts.costs.filter((x) => x.type === 'supplierCost'),
          type: 5,
          name: 'Hankija',
        },
        {
          id: 'extraCosts',
          costs: noTaskCosts.costs.filter((x) => x.type === 'extraCost'),
          type: 6,
          name: 'Lisatöö',
        },
        {
          id: 'equipmentCosts',
          costs: noTaskCosts.costs.filter((x) => x.type === 'equipmentCost'),
          type: 4,
          name: 'Tehnika',
        },
      ]"
      :key="templateType.id"
      :id="templateType.id"
      :template="templates.find((x) => x.type === templateType.type)"
      :costs="templateType.costs"
      :name-prefix="templateType.name"
      :added-cost-ids="addedCostIds"
      :show-added="showAdded"
      :task="null"
      :combine-costs-active="combineCostsActive"
      :combined-costs="combinedCosts"
      :mark-as-paid="markAsPaid"
      :status="status"
      @addItem="addItem"
      @selectTemplate="selectTemplate"
      @addToCombinedCosts="addToCombinedCosts"
      :type="templateType.type"
      class="flex"
    />
  </div>
</template>

<script>
import TemplateCost from "@/components/invoices/TemplateCost.vue";

export default {
  name: "NoTaskCosts",
  components: { TemplateCost },
  props: {
    noTaskCosts: {
      type: Object,
      default: () => {},
    },
    templates: {
      type: Array,
      default: () => [],
    },
    addedCostIds: {
      type: Array,
      default: () => [],
    },
    combinedCosts: {
      type: Array,
      default: () => [],
    },
    combineCostsActive: {
      type: Boolean,
      default: false,
    },
    showAdded: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: null,
    },
    markedAsPaid: {
      type: Array,
      default: () => [],
    },
    markAsPaid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleShowAdded() {
      this.$emit("toggleShowAdded");
    },
    addItem(e) {
      this.$emit("addItem", e);
    },
    selectTemplate(template, task) {
      this.$emit("selectTemplate", template, task);
    },
    addToCombinedCosts(e) {
      this.$emit("addToCombinedCosts", e);
    },
  },
};
</script>
<style scoped lang="scss"></style>
