export const addClientModal = {
  namespaced: true,
  state: {
    show: false,
    taskModalOpen: false,
  },
  mutations: {
    open(state, taskModalOpen = false) {
      state.show = true;
      state.taskModalOpen = taskModalOpen;
    },
    close(state) {
      state.show = false;
      state.taskModalOpen = false;
    },
  },
  actions: {
    open({ commit }, data) {
      commit("open", data);
    },
    close({ commit }) {
      commit("close");
    },
  },
};
