<template>
  <div class="modal">
    <div class="modal-body modal-small">
      <div class="modal-header py-2 px-3">
        <h3>Sulge aken</h3>
      </div>
      <div class="modal-content flex-col items-center bg-white">
        <div class="modal-title">
          <span class="mobile:w-full">{{ text }}</span>
        </div>
      </div>
      <div class="modal-bottom-bar justify-center w-full">
        <button @click.stop.prevent="confirm()" class="btn-primary mr-3">
          <span class="px-4">{{ affirmButton }}</span>
        </button>
        <button @click.stop.prevent="closeModal()" class="btn-danger ml-3">
          <span class="px-4">{{ rejectButton }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfirmCloseModal",
  props: {
    text: {
      type: String,
      default: "Olete kindel, et soovite akna sulgeda?",
    },
    affirmButton: {
      type: String,
      default: "Jah",
    },
    rejectButton: {
      type: String,
      default: "Ei",
    },
    headerText: {
      type: String,
      default: "Kinnitus",
    },
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleKeyDown(e) {
      if (e.keyCode === 13) {
        this.confirm();
      } else if (e.keyCode === 27) {
        this.closeModal();
      }
    },
    confirm() {
      this.$emit("confirm");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss"></style>
