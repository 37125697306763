<template>
  <div
    class="w-full gwd-table"
    v-if="(filter && filter[type.substring(8)]) || filter[type.substring(1)]"
  >
    <div
      class="gwd-table-row cursor-pointer grid-cols-12"
      @click.stop.prevent="expandTable"
      :class="
        actualCostsFiltered.some((x) => x.status !== 1)
          ? actualCostsFiltered.some((x) => x.status === 1)
            ? 'bg-offwhite-dark'
            : 'bg-bordergrey opacity-50'
          : 'bg-offwhite'
      "
    >
      <div class="col-span-2 flex items-center h-full">
        <div
          class="flex flex-row h-full mr-3 items-center"
          v-html="parseCostColorAndName(type)"
          style="min-width: 7px"
        />
        <span>{{ name }}</span>
      </div>
      <div class="col-span-4 wider-cost-list">
        <span />
        <span> {{ plannedCostsFiltered.length }}</span>
        <span>{{ round2(totalPlannedAmount) }} {{ unit }}</span>
        <span>-</span>
        <span>{{ round2(totalPlannedSum) }} €</span>
      </div>
      <div class="col-span-4 wider-cost-list">
        <span />
        <span> {{ actualCostsFiltered.length }}</span>
        <span>{{ round2(totalActualAmount) }} {{ unit }}</span>
        <span>-</span>
        <span>{{ round2(totalActualSum) }} €</span>
      </div>
      <div
        v-if="!displayMode"
        class="col-span-2 flex flex-row justify-between items-center text-sm"
      >
        <button
          @click.stop.prevent="
            !combined && plannedCostsFiltered.length > 0 ? addPlannedCost() : ''
          "
          :class="
            !combined && plannedCostsFiltered.length > 0
              ? 'btn-primary'
              : 'btn-disabled'
          "
          :disabled="!combined && plannedCostsFiltered.length === 0"
        >
          Lisa plan.
        </button>
        <button
          @click.stop.prevent="
            !combined && actualCostsFiltered.length > 0 ? addActualCost() : ''
          "
          :class="
            !combined && actualCostsFiltered.length > 0
              ? 'btn-primary'
              : 'btn-disabled'
          "
          :disabled="!combined && actualCostsFiltered.length === 0"
        >
          Lisa tegelik
        </button>
        <button
          :class="
            !combined &&
            (plannedCostsFiltered.length > 0 || actualCostsFiltered.length > 0)
              ? 'btn-primary'
              : 'btn-disabled'
          "
          :disabled="
            !combined &&
            plannedCostsFiltered.length === 0 &&
            actualCostsFiltered.length === 0
          "
          @click.stop.prevent="
            !combined &&
            (plannedCostsFiltered.length > 0 || actualCostsFiltered.length > 0)
              ? addToLocalCombinedCosts()
              : ''
          "
        >
          Kombineeri
        </button>
      </div>
    </div>
    <div v-if="expanded" class="flex flex-col">
      <div
        class="grid grid-cols-12"
        v-for="index in Math.max(
          plannedCostsFiltered.length,
          actualCostsFiltered.length
        )"
        :key="index"
      >
        <span class="col-span-2" />
        <cost-item
          v-if="plannedCostsFiltered.length >= index"
          :item="plannedCostsFiltered[index - 1]"
        />
        <div v-else class="col-span-4 narrow-cost-list" />
        <cost-item
          v-if="actualCostsFiltered.length >= index"
          :item="actualCostsFiltered[index - 1]"
          :planned="false"
        />
        <div v-else class="col-span-4 narrow-cost-list" />
        <div class="col-span-2" />
      </div>
    </div>
  </div>
</template>
<script>
import CostTypeParser from "@/assets/mixins/CostTypeParser";
import { round2 } from "@/assets/utils/commonMath";
import CostItem from "@/components/invoices/CostItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "CombinedElementCost",
  components: { CostItem },
  props: {
    plannedCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    actualCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    addedCostIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filter: {
      type: Object,
      default: null,
    },
    combinedCosts: {
      type: Array,
      default: () => {
        return [];
      },
    },

    displayMode: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CostTypeParser],
  data() {
    return {
      type: "",
      name: "",
      unit: "",
      expanded: false,
      combined: false,
    };
  },
  methods: {
    round2,
    addToLocalCombinedCosts() {
      this.combined = true;
      this.$emit(
        "addToLocalCombinedCosts",
        this.plannedCostsFiltered.concat(this.actualCostsFiltered)
      );
    },
    expandTable() {
      this.expanded = !this.expanded;
    },
    addPlannedCost() {
      this.$emit("addItem", {
        amount: round2(this.totalPlannedAmount),
        price: round2(
          this.plannedSumNoTax /
            (this.totalPlannedAmount !== 0 ? this.totalPlannedAmount : 1)
        ),
        description: this.name,
        unit: this.unit,
        revenueTypeArray: this.revenueTypeArray,
      });
    },
    addActualCost() {
      if (this.totalActualAmount === 1) {
        this.$emit("addItem", this.actualCosts[0]);
        return;
      }
      // amount sum if price and vat match.
      let price, vat;
      const firstItem = this.actualCosts[0];
      if (this.actualCosts.every((x) => x.price === firstItem.price)) {
        price = firstItem.price;
      }
      if (this.actualCosts.every((x) => x.vat === firstItem.vat)) {
        vat = firstItem.vat;
      }
      this.$emit("addItem", {
        amount: round2(this.totalActualAmount),
        price: price
          ? price
          : round2(
              this.totalSumNoTax /
                (this.totalActualAmount !== 0 ? this.totalActualAmount : 1)
            ),
        description: this.name,
        unit: this.unit,
        revenueTypeArray: this.revenueTypeArray,
        vat: vat ? vat : null,
      });
    },
  },
  mounted() {
    if (this.plannedCosts.length > 0) {
      const item = this.plannedCosts[0];
      this.type = item.type;
      this.name = item.related_object_name;
      this.unit = item.unit;
    } else if (this.actualCosts.length > 0) {
      const item = this.actualCosts[0];
      this.type = item.type;
      this.name = item.related_object_name;
      this.unit = item.unit;
    }
  },
  computed: {
    ...mapGetters({
      taxTypes: "companyVariables/taxTypes",
    }),
    plannedCostsFiltered() {
      return this.plannedCosts.filter(
        (x) => !this.addedCostIds.includes(`${x.type}${x.id}`)
      );
    },
    actualCostsFiltered() {
      return this.actualCosts.filter(
        (x) => !this.addedCostIds.includes(`${x.type}${x.id}`)
      );
    },
    revenueTypeArray() {
      return this.plannedCosts
        .map((x) => `${x.type}${x.id}`)
        .concat(this.actualCosts.map((y) => `${y.type}${y.id}`));
    },
    totalPlannedAmount() {
      return this.plannedCosts.reduce((prev, curr) => prev + curr.amount, 0);
    },
    totalActualAmount() {
      return this.actualCosts.reduce((prev, curr) => prev + curr.amount, 0);
    },
    totalPlannedSum() {
      return this.plannedCosts.reduce(
        (prev, curr) =>
          prev +
          curr.amount * curr.price +
          (curr.amount *
            curr.price *
            parseFloat(
              curr.vat
                ? this.taxTypes.find((x) => x.id === curr.vat).tax_percentage
                : "0"
            )) /
            100,
        0
      );
    },
    plannedSumNoTax() {
      return this.plannedCosts.reduce(
        (prev, curr) => prev + curr.amount * curr.price,
        0
      );
    },
    totalSumNoTax() {
      return this.actualCosts.reduce(
        (prev, curr) => prev + curr.amount * curr.price,
        0
      );
    },
    totalActualSum() {
      return this.actualCosts.reduce(
        (prev, curr) =>
          prev +
          curr.amount * curr.price +
          (curr.amount *
            curr.price *
            parseFloat(
              curr.vat
                ? this.taxTypes.find((x) => x.id === curr.vat).tax_percentage
                : "0"
            )) /
            100,
        0
      );
    },
  },
};
</script>

<style scoped lang="scss">
.combined-element-cost {
  grid-template-columns: 0.1fr 0.4fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr;
}
.wider-cost-list {
  @apply grid items-center w-full py-1;
  grid-template-columns: 0.3fr 0.3fr 0.2fr 0.2fr 0.2fr;
}
.narrow-cost-list {
  @apply grid items-center w-full py-1;
  grid-template-columns: 0.6fr 0.2fr 0.2fr 0.2fr;
}
</style>
