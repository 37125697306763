<template>
  <div class="flex flex-col" v-if="invoice">
    <div class="page-header">
      <div class="flex items-center w-full">
        <div class="mr-3 cursor-pointer">
          <button
            class="btn-danger"
            @click="$router.push('/invoices/outgoing/')"
          >
            Tagasi
          </button>
        </div>
        <div class="title">Arve nr {{ invoice.invoice_nr }}</div>
        <div class="ml-auto">
          <router-link
            v-if="invoice.project"
            :to="`/projects/${invoice.project.uuid}`"
            target="_blank"
          >
            {{ invoice.project.title }} /
          </router-link>
          <span>
            {{
              `${
                invoice.added_by ? invoice.added_by.display_username : "-"
              } / ${
                invoice.created_at
                  ? moment(invoice.created_at).format("DD.MM.YYYY")
                  : "-"
              }`
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex mb-4 gap-x-4">
      <div class="data-container w-2/3 mb-auto">
        <div class="data-container-header">
          <h3>Üldinfo</h3>
          <div class="flex ml-auto">
            <button class="btn-primary mr-3" @click="copyInvoice(false)">
              Kopeeri arvet
            </button>
            <button class="btn-primary mr-3" @click="copyInvoice(true)">
              Kopeeri arvet ning tulusid
            </button>
            <button class="btn-primary" @click="createCreditInvoice">
              Koosta kreeditarve
            </button>
          </div>
        </div>
        <div class="data-container-content data-container-content-hor">
          <div class="flex flex-col w-full pr-2 mobile:pr-0 mobile:w-full">
            <div class="invoice-form-row grid-cols-4 gap-x-2">
              <div class="title col-span-2">
                <gwd-formlabel title="Arve klient" />
              </div>
              <div class="title">
                <gwd-formlabel title="Registrikood" />
              </div>
              <div class="title">
                <gwd-formlabel title="KMKR kood" />
              </div>
              <div class="content col-span-2">
                <router-link
                  :to="`/clients/${invoice.client.id}`"
                  target="_blank"
                >
                  {{
                    invoice.client.name === invoice.client.invoice_name
                      ? invoice.client.name
                      : `${invoice.client.name} (vana nimi ${invoice.client.invoice_name})`
                  }}
                </router-link>
              </div>
              <div class="content">
                <span> {{ invoice.client.register_code }}</span>
              </div>
              <div class="content">
                <span>
                  {{
                    invoice.client.tax_code ? invoice.client.tax_code : "-"
                  }}</span
                >
              </div>
            </div>
            <div
              class="invoice-form-row grid-cols-4 gap-x-2"
              v-if="invoice.payer"
            >
              <div class="title col-span-2">
                <gwd-formlabel title="Arve maksja" />
              </div>
              <div class="title">
                <gwd-formlabel title="Registrikood" />
              </div>
              <div class="title">
                <gwd-formlabel title="KMKR kood" />
              </div>
              <div class="content col-span-2">
                <router-link
                  :to="`/clients/${invoice.payer.id}`"
                  target="_blank"
                >
                  {{
                    invoice.payer.name === invoice.payer.invoice_name
                      ? invoice.payer.name
                      : `${invoice.payer.name} (vana nimi ${invoice.payer.invoice_name})`
                  }}
                </router-link>
              </div>
              <div class="pl-2 content">
                <span> {{ invoice.payer.register_code }}</span>
              </div>
              <div class="pr-2 content">
                <span> {{ invoice.payer.tax_code }}</span>
              </div>
            </div>
            <div class="invoice-form-row grid-cols-4 gap-x-2">
              <div class="title">
                <gwd-formlabel title="Summa (-km)" />
              </div>
              <div class="title">
                <gwd-formlabel title="Käibemaks" />
              </div>
              <div class="title">
                <gwd-formlabel title="Summa kokku" />
              </div>
              <div class="title">
                <gwd-formlabel title="Valuuta" :required="false" />
              </div>
              <div class="content">
                <span>{{
                  `${round2(invoice.no_tax_sum)}${
                    invoice.currency && currency ? currency.sign : "€"
                  }`
                }}</span>
              </div>
              <div class="content flex-col">
                <span>{{
                  `${round2(invoice.tax_sum)}${
                    invoice.currency && currency ? currency.sign : "€"
                  }`
                }}</span>
              </div>
              <div class="content">
                <span class="flex-grow">
                  {{
                    `${round2(invoice.total_sum)}${
                      invoice.currency && currency ? currency.sign : "€"
                    }`
                  }}
                </span>
              </div>
              <div
                class="content"
                v-if="invoice.currency && currency && currency.code !== 'EUR'"
              >
                {{ convertedListSum }}€
              </div>
              <div
                class="content"
                v-if="invoice.currency && currency.code !== 'EUR'"
              >
                {{ convertedTaxSum }}€
              </div>
              <div
                class="content"
                v-if="invoice.currency && currency && currency.code !== 'EUR'"
              >
                {{ convertedTotalSum }}€
              </div>
              <div class="content">
                <span v-if="invoice.currency">{{ invoice.currency }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="invoice-form-row grid-cols-4 gap-x-2">
              <div class="title col-span-2">
                <gwd-formlabel title="Kreeditarve" />
              </div>
              <div class="title">
                <gwd-formlabel title="Arve tüüp" />
              </div>
              <div class="title">
                <gwd-formlabel title="Kurss" :required="false" />
              </div>
              <div class="content items-center col-span-2">
                <span v-if="invoice.reference_number">
                  {{ invoice.reference_number }}
                </span>
                <span v-else>-</span>
              </div>

              <div class="content">
                {{ ["Ülekanne", "Sularaha", "Kaardimakse"][invoice.type - 1] }}
              </div>
              <div class="content">
                <span v-if="invoice.currency_rate"
                  >{{ invoice.currency_rate }} {{ invoice.currency }} = 1
                  EUR</span
                >
                <span v-else>-</span>
              </div>
            </div>
            <div class="invoice-form-row grid-cols-4 gap-x-2">
              <div class="title">
                <gwd-formlabel title="Teenuse kuupäev" />
              </div>
              <div class="title">
                <gwd-formlabel title="Arve kuupäev" />
              </div>
              <div class="title">
                <gwd-formlabel title="Maksetähtaeg (päeva)" />
              </div>
              <div class="title">
                <gwd-formlabel title="Maksetähtpäev" />
              </div>
              <div class="content">
                <span>{{
                  moment(invoice.received_date).format("DD.MM.YYYY")
                }}</span>
              </div>
              <div class="content">
                <span>{{
                  moment(invoice.invoice_date).format("DD.MM.YYYY")
                }}</span>
              </div>
              <div class="content">
                <span>{{ invoice.payment_term }}</span>
              </div>
              <div class="content">
                <span>{{ moment(invoice.due_date).format("DD.MM.YYYY") }}</span>
              </div>
            </div>
            <div class="invoice-form-row">
              <div class="title">
                <gwd-formlabel title="Arve kirjeldus" />
              </div>
              <div class="content">
                <span class="whitespace-pre-wrap">
                  {{ invoice.invoice_content_text }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="data-container-header"><h3>Arve read</h3></div>
        <div class="flex flex-col items-start text-sm mb-auto">
          <revenue-list
            :save-button="false"
            :revenues-prop="invoiceRevenues"
            :new-costs-prop="null"
            :read-only="true"
            :currency="currency"
            :currency-rate="currencyRate"
            ref="costList"
          />
        </div>
      </div>
      <div class="data-container w-1/3">
        <div class="data-container-header">
          <h3>Arve saatmise info</h3>
        </div>
        <div class="data-container-content max-w-full">
          <div
            v-for="sendingData in invoice.sending_datas"
            :key="sendingData.id"
            class="flex flex-col mb-4 max-w-full"
          >
            <div class="flex flex-1 w-full mobile:flex-col">
              <div class="invoice-form-row grid-cols-2 flex-grow mobile:w-full">
                <div class="title">
                  <gwd-formlabel title="Arve saatja" />
                </div>
                <div class="title">
                  <gwd-formlabel title="Saatmise kuupäev" />
                </div>
                <div class="content">
                  {{ sendingData.confirm_profile.display_username }}
                </div>
                <div class="content">
                  {{ moment(sendingData.created_at).format("DD.MM.YYYY") }}
                </div>
              </div>
              <div class="flex flex-col w-20 gap-y-1">
                <div class="flex justify-between items-center">
                  <h4>E-arve</h4>
                  <span
                    class="typcn rounded-full h-5 w-5 flex text-white justify-center items-center leading-none"
                    :class="
                      sendingData.invoice_sent
                        ? 'typcn-tick bg-primary'
                        : 'typcn-times bg-danger'
                    "
                  />
                </div>
                <div class="flex justify-between items-center">
                  <h4>Meil</h4>
                  <span
                    class="typcn rounded-full h-5 w-5 flex text-white justify-center items-center leading-none"
                    :class="
                      sendingData.email_sent
                        ? 'typcn-tick bg-primary'
                        : 'typcn-times bg-danger'
                    "
                  />
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-2 border-t border-offwhite-dark items-center py-2 mobile:flex-col mobile:grid-cols-1 mobile:gap-y-1"
            >
              <div class="flex items-center gap-x-2">
                <h4>PDF</h4>
                <div class="content">
                  <div class="file-display read-only">
                    <span
                      class="export-button"
                      @click="exportFile(sendingData, true)"
                    >
                      {{
                        sendingData.pdf_file.split("/")[
                          sendingData.pdf_file.split("/").length - 1
                        ]
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex items-center gap-x-2">
                <h4>XML</h4>
                <div class="content">
                  <div class="file-display read-only">
                    <span
                      class="export-button"
                      @click="exportFile(sendingData, false)"
                    >
                      {{
                        sendingData.xml_file.split("/")[
                          sendingData.xml_file.split("/").length - 1
                        ]
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-col max-w-full w-full border-t border-offwhite-dark gap-y-2"
              v-if="sendingData.email_sent"
            >
              <h4 class="flex">{{ sendingData.email_title }}</h4>
              <div
                class="flex max-w-full mobile:w-full mobile:flex-col mobile:gap-y-1"
              >
                <div
                  v-for="email in sendingData.email_addresses.split(';')"
                  :key="email"
                  class="flex items-center gap-x-1"
                >
                  <div
                    class="flex h-5 w-5 bg-primary justify-center items-center leading-none text-white rounded-full"
                  >
                    <span class="typcn typcn-user" />
                  </div>

                  <span>{{ email }}</span>
                </div>
              </div>
              <div class="flex flex-1 max-w-full w-full">
                <span
                  class="whitespace-pre-wrap max-w-full flex break-words mobile:w-full"
                  :style="{ hyphens: 'auto' }"
                >
                  {{ sendingData.email_body }}
                </span>
              </div>
            </div>

            <div class="flex">
              <button
                class="btn-primary"
                @click="toggleEmailSending(sendingData)"
              >
                Saada uus meil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <email-sending-modal
      v-if="newEmailSending"
      :sending-info="newEmailSendingData"
      @sendNewEmail="sendNewEmail"
      @closeModal="
        () => {
          this.newEmailSending = false;
          this.newEmailSendingData = null;
        }
      "
    />
    <div class="flex" v-if="invoice.permissions === 'R1'">
      <button
        class="btn-danger"
        @click="$router.push(`/invoices/outgoing/${invoice.id}`)"
      >
        Muuda arvet
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { convertCurrency, round2 } from "@/assets/utils/commonMath";
import { saveAs } from "file-saver";
import RevenueList from "@/components/invoices/RevenueList.vue";
import { Revenue } from "@/assets/types/Revenue";
import EmailSendingModal from "@/components/invoices/EmailSendingModal.vue";

export default {
  name: "SentOutgoingInvoice",
  components: { EmailSendingModal, RevenueList },
  data() {
    return {
      returnUrl: null,
      invoice: null,
      moment: moment,
      currencyRate: null,
      invoiceRevenues: [],
      newEmailSending: false,
      newEmailSendingData: null,
    };
  },
  methods: {
    round2,
    convertCurrency,
    sendNewEmail(e) {
      console.log(e);
      this.apiRequest(
        `invoice/${this.companyId}/outgoing/${this.$route.params.invoiceId}/info/${this.newEmailSendingData.id}/resend/`,
        "post",
        true,
        e
      )
        .then((res) => {
          if (res.status === 201) {
            this.newEmailSendingData = null;
            this.newEmailSending = false;
            this.retrieveInvoice();
          }
        })
        .catch(() => {
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 15,
          });
        });
    },
    toggleEmailSending(e) {
      this.newEmailSendingData = e;
      this.newEmailSending = true;
    },
    retrieveInvoice() {
      this.apiRequest(
        `invoice/${this.companyId}/outgoing/${this.$route.params.invoiceId}/?view=sent`,
        "get",
        true
      ).then((res) => {
        this.invoice = JSON.parse(JSON.stringify(res.data));
        document.title = `Müügiarve ${res.data.invoice_nr}`;
        this.currencyRate = this.invoice.currency_rate;
        this.loadInvoiceRevenues();
      });
    },
    loadInvoiceRevenues() {
      this.apiRequest(
        `invoice/${this.companyId}/outgoing/${this.$route.params.invoiceId}/revenues/`,
        "get",
        true
      ).then((res) => {
        this.invoiceRevenues = res.data.map((x) => {
          return new Revenue(
            x.id,
            this.serviceObjects.find(
              (y) => parseInt(y.service_code) === parseInt(x.service_code)
            ),
            x.amount,
            x.unit,
            this.taxTypes.find((y) => parseInt(y.accounting_code) === x.vat),
            x.price,
            null,
            x.service_name
          );
        });
      });
    },
    initReturnUrl(path) {
      this.returnUrl = `${path}?page=4`;
    },
    closeDetailView() {
      if (this.returnUrl) {
        this.$router.push(this.returnUrl);
        return;
      }
      this.$router.push("/outgoing/invoices/");
    },
    exportFile(sendingData, pdf) {
      this.axiosInstance({
        method: "get",
        url: `invoice/${this.companyId}/outgoing/${this.$route.params.invoiceId}/media/${sendingData.id}/?pdf=${pdf}`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access"),
        },
        responseType: "arraybuffer",
      }).then((res) => {
        let blob = new Blob([res.data], {
          type: pdf ? "application/pdf" : "text/xml",
        });
        saveAs(
          blob,
          `${
            pdf
              ? sendingData.pdf_file.split("/")[
                  sendingData.pdf_file.split("/").length - 1
                ]
              : sendingData.xml_file.split("/")[
                  sendingData.xml_file.split("/").length - 1
                ]
          }`
        );
      });
    },
    copyInvoice(revenuesAdded) {
      let data = {
        projectId: this.invoice.project ? this.invoice.project.uuid : null,
        payer: this.invoice.payer ? this.payer.id : null,
        client: this.invoice.client.id,
      };
      let invoiceData = JSON.parse(JSON.stringify(this.invoice));

      if (revenuesAdded) {
        data.revenues = this.invoiceRevenues;
      } else {
        invoiceData.total_sum = 0;
        invoiceData.tax_sum = 0;
        invoiceData.no_tax_sum = 0;
      }
      data.invoiceData = invoiceData;
      this.$store.dispatch("invoice/setInvoiceCopyData", data);
      this.$router.push("/invoices/outgoing/add/");
    },
    createCreditInvoice() {
      let data = {
        projectId: this.invoice.project ? this.invoice.project.uuid : null,
        payer: this.invoice.payer ? this.invoice.payer.id : null,
        client: this.invoice.client.id,
      };
      let invoiceData = JSON.parse(JSON.stringify(this.invoice));
      invoiceData.credit_invoice = true;
      invoiceData.credit_invoice_source = this.invoice.invoice_nr;

      invoiceData.total_sum *= -1;
      invoiceData.tax_sum *= -1;
      invoiceData.no_tax_sum *= -1;

      data.invoiceData = invoiceData;
      this.invoiceRevenues.forEach((x) => (x.price *= -1));
      data.revenues = this.invoiceRevenues;

      this.$store.dispatch("invoice/setInvoiceCopyData", data);
      this.$router.push("/invoices/outgoing/add/");
    },
  },
  async mounted() {
    if (!this.taxTypes)
      await this.$store.dispatch("companyVariables/retrieveTaxTypes");
    if (!this.currencyList)
      await this.$store.dispatch("companyVariables/retrieveCurrencies");
    if (!this.serviceObjects)
      await this.$store.dispatch("companyVariables/retrieveServiceObjects");
    this.retrieveInvoice();
  },
  beforeRouteEnter(to, from, next) {
    return next((vm) => {
      vm.initReturnUrl(from.path);
    });
  },
  computed: {
    ...mapGetters({
      companyId: "companyData/activeCompanyUuid",
      currencyList: "companyVariables/currencyList",
      serviceObjects: "companyVariables/serviceObjects",
      taxTypes: "companyVariables/taxTypes",
    }),
    currency() {
      if (this.currencyList && this.invoice)
        return this.currencyList.find((x) => x.code === this.invoice.currency);
      return null;
    },
    convertedListSum() {
      if (!this.invoice.no_tax_sum || !this.currencyRate)
        return this.invoice.no_tax_sum;
      return this.convertCurrency(
        parseFloat(this.invoice.no_tax_sum),
        this.currencyRate
      );
    },
    convertedTaxSum() {
      if (!this.invoice.tax_sum || !this.currencyRate)
        return this.invoice.tax_sum;
      return this.convertCurrency(this.invoice.tax_sum, this.currencyRate);
    },
    convertedTotalSum() {
      if (!this.invoice.total_sum || !this.currencyRate)
        return this.invoice.total_sum;
      return this.convertCurrency(this.invoice.total_sum, this.currencyRate);
    },
  },
};
</script>
<style scoped lang="scss">
.file-display {
  @apply shadow  w-full rounded flex duration-100;
  .export-button {
    @apply w-5/6 truncate py-1 px-2 rounded-l cursor-pointer duration-100;
    &:hover {
      @apply bg-primary text-white;
    }
  }
  .delete-button {
    @apply w-1/6 bg-danger rounded-r text-white duration-100 flex items-center justify-center;
    &:hover {
      @apply bg-danger-dark;
    }
  }
  &.read-only {
    .delete-button {
      @apply w-0 hidden;
    }
    .export-button {
      @apply w-full rounded;
    }
  }
}
</style>
