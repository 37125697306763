<template>
  <div class="flex flex-col">
    <div class="flex items-start gap-x-2">
      <div
        class="flex flex-col bg-white shadow rounded items-center justify-center w-52"
      >
        <div
          class="bg-offwhite w-full rounded-t justify-center flex py-1 cursor-pointer"
          @click="filterBy = null"
        >
          <h4>Töötaja</h4>
        </div>

        <div
          v-for="worker in taskWorkers"
          :key="worker.id"
          class="p-2 last:rounded-b hover:bg-primary hover:text-white duration-100 w-full justify-center flex cursor-pointer"
          @click="filterBy = worker.id"
        >
          {{ worker.worker_name }}
        </div>
      </div>
      <div class="flex flex-grow flex-col bg-white rounded shadow">
        <div
          class="grid w-full bg-offwhite rounded-t p-2 timer-rows cursor-pointer"
          @click="openGeoMapEveryBody"
        >
          <div class="flex">
            <h4>Töötaja</h4>
          </div>
          <div class="flex"><h4>Kestvus</h4></div>
          <div class="flex"><h4>Kuupäev</h4></div>
          <div class="flex"><h4>Plan. algus</h4></div>
          <div class="flex"><h4>Plan. lõpp</h4></div>
          <div class="flex"><h4>Tegelik algus</h4></div>
          <div class="flex"><h4>Tegelik lõpp</h4></div>
          <div class="flex"><h4>Info</h4></div>
          <div class="flex"></div>
        </div>
        <task-timer-item
          v-for="item in filteredTimerData"
          :key="item.id"
          :item="item"
          @endTimerAsManager="endStopperAsManager"
          @openGeoMap="openGeoMap"
          @confirm="confirmTime"
          @deleteTime="deleteTime"
          @update="updateTime"
          ref="timers"
        />
        <div class="p-2" v-if="filteredTimerData.length === 0">
          <span>Tööaegu pole</span>
        </div>
        <div
          class="grid grid-cols-9 border-t border-offwhite-dark py-2 items-center px-2"
        >
          <div class="flex col-span-2 pr-2">
            <select v-model="stopperToAdd.worker">
              <option
                v-for="worker in taskWorkers"
                :key="worker.id"
                :value="worker.id"
              >
                {{ worker.worker_name }}
              </option>
            </select>
          </div>
          <div class="flex col-span-2">
            <span v-if="stopperProcessed">
              {{
                moment
                  .utc(
                    moment
                      .duration(
                        moment(this.stopperProcessed.stopper_end).diff(
                          moment(this.stopperProcessed.stopper_start)
                        )
                      )
                      .as("milliseconds")
                  )
                  .format("HH:mm:ss")
              }}
            </span>
            <span v-else>-</span>
          </div>
          <div class="flex col-span-2">
            <date-selector
              v-model="stopperToAdd.date"
              :key="`dsrr-${filteredTimerData.length}`"
              :pos="filteredTimerData.length > 5 ? 't' : 'b'"
            />
          </div>
          <div class="flex pr-2">
            <time-selector
              :key="`ts1rr-${filteredTimerData.length}`"
              v-model="stopperToAdd.startTime"
              :pos="filteredTimerData.length > 5 ? 't' : 'b'"
            />
          </div>
          <div class="flex pr-2">
            <time-selector
              :key="`ts2rr-${filteredTimerData.length}`"
              v-model="stopperToAdd.endTime"
              :pos="filteredTimerData.length > 5 ? 't' : 'b'"
            />
          </div>
          <div class="flex">
            <button
              :class="
                saving || !stopperProcessed ? 'btn-disabled' : 'btn-primary'
              "
              @click="saveNewStopper"
              :disabled="saving || !stopperProcessed"
            >
              <ClipLoader size="20px" v-if="saving" />
              <span v-else>Lisa</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DateSelector from "../../globals/DateSelector.vue";
import TimeSelector from "../../reusable/TimeSelector.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import TaskTimerItem from "@/components/tasks/taskmodal/TaskTimerItem.vue";

export default {
  data() {
    return {
      filterBy: null,
      moment: moment,
      stopperToAdd: {
        worker: null,
        date: null,
        startTime: null,
        endTime: null,
      },
      saving: false,
      localTimerData: [],
    };
  },
  components: {
    TaskTimerItem,
    DateSelector,
    TimeSelector,
    ClipLoader,
  },
  methods: {
    openGeoMap(timerData) {
      if (timerData.start_location || timerData.end_location)
        this.$store.dispatch("modals/taskModal/openTimerMap", [timerData]);
    },
    openGeoMapEveryBody() {
      const data = this.timerData.filter(
        (x) => x.start_location || x.end_location
      );
      if (data.length > 0)
        this.$store.dispatch("modals/taskModal/openTimerMap", data);
    },
    confirmTime(workerTime) {
      this.apiRequest(
        `costs/${this.companyId}/worker/${workerTime.worker_id}/${workerTime.cost_id}/confirm/`,
        "post",
        true
      )
        .then((res) => {
          if (res.status === 200) {
            this.$store.dispatch(
              "modals/taskModal/retrieveTimerData",
              this.taskData.id
            );
          }
        })
        .catch((err) => {
          if (
            err.response.status === 409 ||
            { err }.err.response.status === 409
          )
            this.$store.dispatch("messageHandler/throwMessage", {
              text: "Kinnitamine ebaõnnestus, lõppajata aega ei saa kinnitada!",
              type: "error",
              ttl: 15,
            });
        });
    },
    deleteTime(time) {
      this.apiRequest(
        `tasks/${this.companyId}/${time.worker_id}/${time.id}/delete/`,
        "delete",
        true
      ).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch(
            "modals/taskModal/retrieveTimerData",
            this.taskData.id
          );
        }
      });
    },
    updateTime(data) {
      this.apiRequest(
        `tasks/${this.companyId}/${data.workerId}/${data.id}/edit/`,
        "patch",
        true,
        {
          start_time: data.startTime,
          end_time: data.endTime,
        }
      ).then((res) => {
        if (res.status === 200) {
          this.$store.dispatch(
            "modals/taskModal/retrieveTimerData",
            this.taskData.id
          );
        }
      });
    },
    saveNewStopper() {
      this.saving = true;
      this.apiRequest(
        `tasks/${this.companyId}/stopper/create/`,
        "post",
        true,
        this.stopperProcessed
      )
        .then((res) => {
          if (res.status === 201) {
            this.$store.dispatch(
              "modals/taskModal/retrieveTimerData",
              this.taskData.id
            );
            this.stopperToAdd = {
              worker: null,
              date: null,
              startTime: null,
              endTime: null,
            };
            this.saving = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("messageHandler/throwMessage", {
            text: "Midagi läks valesti",
            type: "error",
            ttl: 10,
          });
          this.saving = false;
        });
    },
    endStopperAsManager(e) {
      this.saving = true;
      this.apiRequest(
        `tasks/${this.taskData.id}/stopper/${e.id}/stop/`,
        "put",
        true,
        {}
      ).then((res) => {
        if (res.status === 200) {
          this.$store
            .dispatch("modals/taskModal/retrieveTimerData", this.taskData.id)
            .then(() => {
              this.localTimerData = [];
            });
          this.saving = false;
        }
      });
    },
  },
  mounted() {
    this.localTimerData = JSON.parse(JSON.stringify(this.timerData));
  },
  watch: {
    timerData: {
      deep: true,
      handler() {
        this.localTimerData = JSON.parse(JSON.stringify(this.timerData));
      },
    },
  },
  computed: {
    ...mapGetters({
      timerData: "modals/taskModal/timerData",
      taskWorkers: "modals/taskModal/workers",
      taskData: "modals/taskModal/taskData",
      companyId: "companyData/activeCompanyUuid",
      isManager: "companyData/isManager",
    }),
    filteredTimerData() {
      if (!this.localTimerData) return null;
      if (this.filterBy)
        return this.localTimerData.filter((x) => x.worker_id === this.filterBy);
      return this.localTimerData;
    },
    stopperProcessed() {
      if (
        !this.stopperToAdd.date ||
        !this.stopperToAdd.startTime ||
        !this.stopperToAdd.endTime ||
        !this.taskData.id ||
        !this.stopperToAdd.worker
      )
        return null;

      let data = {
        worker: this.stopperToAdd.worker,
        stopper_start: moment(
          `${moment(this.stopperToAdd.date).format("YYYY-MM-DD")} ${
            this.stopperToAdd.startTime.hour
          }:${this.stopperToAdd.startTime.minute}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        stopper_end: moment(
          `${moment(this.stopperToAdd.date).format("YYYY-MM-DD")} ${
            this.stopperToAdd.endTime.hour
          }:${this.stopperToAdd.endTime.minute}`
        ).format("YYYY-MM-DD[T]HH:mm:ssZ"),
        task: this.taskData.id,
      };
      if (this.taskData.related_object)
        data.project = this.taskData.related_object.object_uuid;
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.timer-rows {
  grid-template-columns: 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.5fr 0.2fr;
}
</style>
