<template>
  <div class="modal">
    <div class="modal-body modal-medium" v-on-clickaway:mousedown="closeModal">
      <div class="modal-header px-2 py-1">
        <h3>Töötajale asukoha lisamine/muutmine</h3>
      </div>
      <div class="modal-content flex-col mb-4">
        <div class="flex p-2" v-if="data.currLocation">
          Hetke asukoht: {{ data.currLocation.long_address }}
        </div>
        <div
          class="flex p-2"
          v-if="data.currLocation && data.currLocation.info"
        >
          Info: {{ data.currLocation.info }}
        </div>
        <planning-location-selector v-model="selectedLocation" class="p-2">
          Asukoha valik?
        </planning-location-selector>
      </div>
      <div class="modal-bottom-bar gap-x-3">
        <button
          :class="selectedLocation ? 'btn-primary' : 'btn-disabled'"
          @click="saveLocation"
        >
          <span>Vali asukoht</span>
        </button>
        <button class="btn-danger" @click="closeModal">
          <span class="typcn typcn-times icon" />
          <span class="label">Sulge</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/assets/mixins/EventBus";
import PlanningLocationSelector from "@/components/tasks/planning/PlanningLocationSelector.vue";

export default {
  name: "LocationInaddressModal",
  components: { PlanningLocationSelector },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedLocation: null,
    };
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
  methods: {
    saveLocation() {
      EventBus.$emit("subTaskLocationUpdated", {
        ...this.data,
        selectedLocation: this.selectedLocation,
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss"></style>
