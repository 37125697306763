import StoreRequestHandler from "@/assets/mixins/StoreRequestHandler";
import moment from "moment";
export const calendar = {
  namespaced: true,
  actions: {
    retrieveCalendarData(ctx, data) {
      /*
        companyId: string required
        start: string, date in "YYYY-MM-DD" required
        period: string, day, week, month, all, required
        worker: number, worker id
        individual: bool,
        project: string, project uuid,
        isClient: bool
      */
      let url;
      const startDate = data.start ? moment(data.start) : moment();
      const dateFormat = "YYYY-MM-DD";
      const date = moment(startDate).format(dateFormat);

      if (data.project)
        url = `calendar/${data.companyId}/${data.project.uuid}/tasks/${
          data.isClient ? "client/" : ""
        }?period=${data.period}&start_time=${date}`;
      else if (data.worker)
        url = `calendar/${data.companyId}/tasks/${data.worker}/?period=${data.period}&start_time=${date}`;
      else if (data.equipment)
        url = `template/${data.companyId}/equipment/${data.equipment}/assignments/?period=${data.period}&start_time=${date}`;
      else
        url = `calendar/${data.companyId}/${
          data.individual ? "individual/" : ""
        }?period=${data.period ?? "week"}&start_time=${date}`;

      return StoreRequestHandler.apiRequest(url, "get", true);
    },
    retrieveEquipmentScheduleData(ctx, data) {
      /*
        companyId: req, string, companyId
        equipment: req, object, equipment object
        period: str, day, week, month
        startTime: str, YYYY-MM-DD/YYYY-MM formatted string
      */
      return StoreRequestHandler.apiRequest(
        `template/${data.companyId}/equipment/${data.equipment.id}/unavailability/?period=${data.period}&start_time=${data.startTime}`,
        "get",
        true
      );
    },
  },
};
